import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; 
import {
  getChat,
  getChatSupport,
  postChat,
  postChatSupport,
} from "../network/userNet";

import { setLogout } from "../redux/actions/userActions";
import { supabase } from "../supabase/supabaseClient";

function Chat() {
  const [dataChat, setDataChat] = useState([]);
  const [dataChatSupport, setDataChatSupport] = useState([]); 
  
  const [msg, setMsg] = useState("");
  const messagesEndRef = useRef(null);
  const messagesEndRef2 = useRef(null);

  const logged = useSelector((state) => state.userReducer.logged);
  const userId = useSelector((state) => state.userReducer.userId); 
  const dispatch = useDispatch();

  supabase
    .from("chat_cashbaunty")
    .on("INSERT", (message) => { 
      setDataChat((oldArray) => [...oldArray, message.new]);
    })
    .subscribe();

  useEffect(() => {
    getMessageGeneral();
    getMessageSupport();
  }, []);

  function getMessageGeneral() {
    getChat().then(function (response) { 
      if (response.data.status === -1) { 
        dispatch(setLogout());
      } else {
        setDataChat(response.data.msg);
      }
    });
  }
  function getMessageSupport() {
    getChatSupport().then(function (response) { 

      if (response.data.status === -1) { 
        // dispatch(setLogout());
      } else {
        setDataChatSupport(response.data.message);
      }
    });
  }
  //////// scroll chat
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
    messagesEndRef2.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [dataChat,dataChatSupport]);

 

  //////// end scroll chat
  function postNewmessage() {
    var data = {
      id: userId,
      type: "message",
      msg: msg,
    };
    postChat(data).then(function (response) { 
      if (response.data.status === -1) {  
        dispatch(setLogout());
      } 
      setMsg("");
    });
  }

  function postNewmessageSupport() {
    var data = {
      id: userId,
      type: "message",
      msg: msg,
    };
    postChatSupport(data).then(function (response) { 
      if (response.data.status === -1) { 
        dispatch(setLogout());
      } else if (response.data.status === 1) {
        getMessageSupport();
      } else {
        alert(response.data.message);
      }
      setMsg("");
    });
  }

  return (
    <>
      <div className="freecash--chat">
        <button className="chat--btn chat--btn--lft">
          <img src="assets/image/icon/icon-lft.png" alt="" />
        </button>
        <div className="chat--box">
          <nav className="chat--tabs--nav">
            <ul>
              <li>
                <a href="#tab1">
                  <img src="assets/image/icon/chat-dots.png" alt="" /> General
                </a>
              </li>
              <li>
                <a href="#tab2">
                  <img src="assets/image/icon/support.png" alt="" /> Support
                </a>
              </li>
            </ul>
          </nav>
          <section className="chat--tabs--content">
            <div id="tab1" className="first--tab tab--content">
              <div className="chat--body">
                {dataChat !== []
                  ? dataChat.map((item, index) => (
                      <div key={index} className="chat--item">
                        <div className="chat--itm--top">
                          <div className="chat--person">
                            <div className="chat--person-pic">
                              
                               <div className="account--img">
                                <img src={'../avatars/'+item.img} alt="" />
                                </div>
                            </div>
                            <div className="chat--person--name">
                              {item.name}{" "}
                            </div>
                          </div>
                          {/* <div className="chat--person--coin">10</div> */}
                        </div>
                        <div className="chat--itm--bottom">
                          <div className="chat--message">{item.message}</div>
                          <div className="chat--time">{item.updated_at}</div>
                        </div>
                      </div>
                    ))
                  : "empty !"}
              </div>
              <div ref={messagesEndRef} />
              <div className="chat--bottom">
              {logged ? (
                <div className="send--message--box">
                  <textarea
                    value={msg}
                    onChange={(e) => setMsg(e.target.value)}
                    placeholder="Say something..."
                  ></textarea>
                  <button className="btn--send" onClick={postNewmessage}>
                    <img src="assets/image/icon/send.png" alt="" />  
                  </button>
                </div>
              ) : (
                <div className="disableInput">
                  <span>X</span> Sign in to unlock the chat
                </div>
              )}
            </div>
            </div>
            <div id="tab2" className="second--tab tab--content">
              <p>
                Send us a support message at any time and we'll get back to you
                as soon as possible! Tip: If you are missing coins, please check
                the FAQ section before contacting support.
              </p>
              <div className="support--body">
                {dataChatSupport !== []
                  ? dataChatSupport.map((item, index) => (
                      <div key={index} className="chat--item">
                        <div className="chat--itm--top">
                          <div className="chat--person">
                            <div className="chat--person--name">
                              {item.is_staff === 0 ? "you" : "support"}{" "}
                            </div>
                          </div>
                          {/* <div className="chat--person--coin">10</div> */}
                        </div>
                        <div className="chat--itm--bottom">
                          <div className="chat--message">{item.message}</div>
                          <div className="chat--time">{item.date}</div>
                        </div>
                      </div>
                    ))
                  : "empty !"}
              </div>
              <div ref={messagesEndRef2} />
              <div className="chat--bottom">
                {logged ? (
                  <div className="send--message--box">
                    <textarea
                      value={msg}
                      onChange={(e) => setMsg(e.target.value)}
                      placeholder="Say something..."
                    ></textarea>
                    <button className="btn--send" onClick={postNewmessageSupport}>
                      <img src="assets/image/icon/send.png" alt="" />{" "}
                      
                    </button>
                  </div>
                ) : (
                  <div className="disableInput">
                    <span>X</span> Sign in to unlock the chat
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Chat;
